import {useContext, useState} from 'react';
import React from 'react';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import {ThemeContext} from '../../contexts/theme';
import {projects, skills, contact} from '../../data';
import './Navbar.css';
import {Box} from '@mui/material';
import {useGeneralEndPoint} from '../../GeneralEndPoint';

const Navbar = () => {
  const {isMobile} = useGeneralEndPoint();
  return (
    <nav className="center nav">
      <ul
        style={{
          display: 'flex',
          justifyContent: isMobile ? 'space-between' : undefined,
          width: isMobile ? '80vw' : 'auto',
        }}
      >
        {projects.length ? (
          <li className="nav__list-item">
            <a href="#projects" className="link link--nav">
              Projects
            </a>
          </li>
        ) : null}

        {skills.length ? (
          <li className="nav__list-item">
            <a href="#skills" className="link link--nav">
              Skills
            </a>
          </li>
        ) : null}

        {contact.email ? (
          <li className="nav__list-item">
            <a href="#contact" className="link link--nav">
              Contact
            </a>
          </li>
        ) : null}
      </ul>

      {/* <Box onClick={toggleTheme} className="btn btn--icon nav__theme" aria-label="toggle theme">
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </Box> */}

      {/* <button
        type="button"
        onClick={toggleNavList}
        className="btn btn--icon nav__hamburger"
        aria-label="toggle navigation"
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button> */}
    </nav>
  );
};

export default Navbar;
