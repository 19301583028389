import React, {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ChildrenProp, ThemeContextProp} from '../types';

const ThemeContext = createContext({} as ThemeContextProp);
export const useCustomTheme = () => React.useContext(ThemeContext);
const ThemeProvider = ({children}: ChildrenProp) => {
  const [isDark, setIsDark] = useState(false);
  const pallette: ThemeContextProp['pallette'] = {
    textPrimary: '#CDCDFF',
    textSecondary: '#90A0D9',
    background: '#23283E',
  };
  useEffect(() => {
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDark(darkMediaQuery.matches ? true : false);
    darkMediaQuery.addEventListener('change', (e) => {
      setIsDark(e.matches ? true : false);
    });
  }, []);

  const toggleTheme = () => {
    localStorage.setItem('isDark', isDark ? '1' : '');
    setIsDark(!isDark);
  };

  return <ThemeContext.Provider value={{isDark, toggleTheme, pallette}}>{children}</ThemeContext.Provider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {ThemeProvider, ThemeContext};
