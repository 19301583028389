import React from 'react';
import {projects} from './data';
import {GeneralEndPointProps} from './types';
import {useMediaQuery, useTheme} from '@mui/material';

const Context = React.createContext({} as GeneralEndPointProps);
export const useGeneralEndPoint = () => React.useContext(Context);

export const GeneralEndPoint = ({children}) => {
  const projectsTotal = projects.length;
  const theme = useTheme();
  const isMobile: boolean = useMediaQuery(() => theme.breakpoints.down('md'));
  return <Context.Provider value={{projectsTotal, isMobile}}>{children}</Context.Provider>;
};
export default GeneralEndPoint;
