import {Box, Grid, Typography} from '@mui/material';
import {projects} from '../../data';
import ProjectContainer from '../ProjectContainer/ProjectContainer';
import './Projects.css';
import React from 'react';
import {useCustomTheme} from '../../contexts/theme';
import {useGeneralEndPoint} from '../../GeneralEndPoint';

const Projects = () => {
  const {pallette} = useCustomTheme();
  const {isMobile} = useGeneralEndPoint();
  if (!projects.length) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        mt: isMobile ? '4vh' : 0,
      }}
      id="projects"
    >
      <Box sx={{display: 'flex', alignItems: 'center', width: 'max-content'}}>
        <Typography
          sx={{
            fontSize: isMobile ? '2rem' : '3rem',
            fontWeight: 'bold',
            color: pallette.textSecondary,

            mr: '1rem',
          }}
        >
          {projects.length}
        </Typography>
        <Typography
          sx={{
            fontSize: isMobile ? '1.5rem' : '2rem',
            fontWeight: 'bold',
            color: pallette.textPrimary,
          }}
        >
          Projects delivered{' '}
        </Typography>
        <Box
          mx={{
            display: 'flex',
            position: 'relative',
            bottom: '.8rem',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'normal',
              color: pallette.textSecondary,
              transform: 'rotate(-10deg)',
              ml: '.5rem',
            }}
          >
            so far
          </Typography>
          <Typography sx={{pb: '1rem'}}>🚀</Typography>
        </Box>
      </Box>

      <Grid container spacing={10}>
        {projects.map((project, i) => (
          <ProjectContainer key={i} project={project} />
        ))}
      </Grid>
    </Box>
  );
};

export default Projects;
