import {useContext} from 'react';
import {ThemeContext} from './contexts/theme';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import './App.css';
import {Box} from '@mui/material';
import Mui from './contexts/Mui';
import React from 'react';
import GeneralEndPoint from './GeneralEndPoint';

const App = () => {
  const {isDark} = useContext(ThemeContext);

  return (
    <GeneralEndPoint>
      <Mui>
        <div id="top" className={`${isDark ? 'dark' : 'light'} app`}>
          {/* <Header /> */}

          <About />
          <Skills />
          <Box sx={{mx: '10%'}}>
            <Projects />
            <Contact />
          </Box>

          <ScrollToTop />
          <Footer />
        </div>
      </Mui>
    </GeneralEndPoint>
  );
};

export default App;
