import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';
import './ProjectContainer.css';
import {Box, Grid, Link, Typography, useTheme} from '@mui/material';
import {useCustomTheme} from '../../contexts/theme';
import React from 'react';
import {useGeneralEndPoint} from '../../GeneralEndPoint';

const ProjectContainer = ({project}) => {
  const theme = useTheme();
  const {pallette, isDark} = useCustomTheme();

  return (
    <Grid item md={12} lg={4} sx={{width: '100%'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: 'transform 0.2s linear',
          width: '1fr',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
          pt: '1rem',
          height: '100%',
        }}
      >
        <Typography variant="body2" sx={{pb: '1rem'}}>
          {project.name}
        </Typography>
        <Box
          sx={{
            overflow: 'hidden',
            transition: 'transform 0.2s linear',
            border: 'none',
            '&:hover': {
              transform: 'scale(1.1)',
              transition: 'transform 0.2s linear',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
              '&>img': {
                transform: 'scale(1.4)',
                transition: 'transform 1s ease-in-out',
              },
              borderRadius: '0.5rem',
            },
            '&>img': {
              transition: 'transform .5s ease-in-out',
            },
          }}
        >
          <img width="100%" src={window.location.origin + project.img} alt="project" />
        </Box>
        {/* 
        <Typography
          sx={{
            color: !isDark ? '#444' : '#cdcdff',
            mx: '2rem',
            mt: '2rem',
            flex: 1,
          }}
        >
          {project.description}
        </Typography> */}
        {project.stack && (
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              margin: '1.2em 0',
              background: !isDark ? theme.palette.primary.light : '#080808',
            }}
          >
            {project.stack.map((item, index) => (
              <Typography
                key={index}
                style={{
                  margin: ' 0.5em',
                  fontWeight: 500,
                  fontSize: '0.8rem',
                  color: !isDark ? '#444' : '#cdcdff',
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        )}

        {project.sourceCode && (
          <a href={project.sourceCode} aria-label="source code" className="link link--icon">
            <GitHubIcon />
          </a>
        )}

        {project.livePreview && (
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'flex-end',
              '&>p': {
                opacity: 0,
                transform: `translateX('-1')`,
              },
              '&:hover': {
                '&>p': {
                  opacity: 1,
                  transform: 'translateX(1)',
                  transition: 'transform 1s linear',
                },
              },
            }}
          >
            {/* <Typography color="primary">Check Website</Typography> */}
            <Link
              underline="none"
              href={project.livePreview}
              aria-label="live preview"
              className="link link--icon"
              target="_blank"
              style={{}}
            >
              <LaunchIcon />
            </Link>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ProjectContainer;
