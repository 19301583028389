import {skills} from '../../data';
import React from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useCustomTheme} from '../../contexts/theme';
import {useGeneralEndPoint} from '../../GeneralEndPoint';

const Skills = () => {
  const {pallette} = useCustomTheme();
  const {isMobile} = useGeneralEndPoint();
  if (!skills.length) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          top: '-12vh',
          display: ' flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          // height: '50vh',
        }}
        id="skills">
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            height: '10vh',
            position: 'sticky',
            bottom: 10,
          }}>
          {!isMobile && (
            <Button
              sx={{
                fontSize: isMobile ? '1rem' : '7vh',
                fontWeight: 'bold',
                color: pallette.textSecondary,
                height: '10vh',
              }}
              variant="contained"
              onClick={() => {
                return (window.location.href = '#contact');

                // navigator.clipboard.writeText('hi@moatezz.com')
              }}>
              Hire me!!
            </Button>
          )}
        </Box>

        {/* <Typography
        sx={{
          fontSize: '3vh',
          fontWeight: '800',
          color: pallette.textPrimary,
          flexGrow: 1,
          width: '',
          textTransform: 'uppercase',
        }}
      >
        Skills
      </Typography> */}
        <Box
          sx={{
            display: 'flex',

            gap: '.5vh',
            flexWrap: 'wrap',
            alignItems: 'center',
            width: isMobile ? '90%' : '60%',
            p: '1vh',
            // border: `1px solid ${pallette.textSecondary}`,
          }}>
          {skills.map(({y, name, color, fontFamily, fontSize, fontWeight}, i) => (
            <Typography
              key={i}
              sx={{
                fontSize: isMobile ? `${Number(fontSize) / 3}rem` : `${fontSize}vh`,
                fontWeight,
                color,
                // height: fontSize,
                position: 'relative',
                top: y,
                px: '.5vw',
                my: '1vh',
                py: '.4vh',
                // border: '1px solid white',
                background: '#1e2336',
                lineHeight: '80%',
                verticalAlign: 'bottom',
              }}>
              {name}
            </Typography>
          ))}
        </Box>
      </Box>
      {isMobile && (
        <Button
          sx={{
            fontSize: isMobile ? '3rem' : '7vh',
            fontWeight: 'bold',
            color: pallette.textSecondary,
            height: isMobile ? '8vh' : '10vh',
          }}
          variant="contained"
          onClick={() => {
            return (window.location.href = '#contact');

            // navigator.clipboard.writeText('hi@moatezz.com')
          }}>
          Hire me!!
        </Button>
      )}
    </Box>
  );
};

export default Skills;
