import {useEffect, useState} from 'react';
import {contact} from '../../data';
import './Contact.css';

const Contact = () => {
  const [state, setState] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [state]);

  return (
    <section className="section contact center" id="contact">
      <h2 className="section__title">Contact</h2>
      <p className="section__description" style={{marginBottom: 34}}>
        hi@moatezz.com
      </p>
      <a
        href={`mailto:${contact.email}`}
        onClick={() => {
          window.location.href = 'mailto:hi@moatezz.com';
          window.open('mailto:your_email@example.com');
          setState(true);
          navigator.clipboard.writeText('hi@moatezz.com');
        }}
      >
        <span
          type="button"
          className="btn btn--outline"
          style={{
            // cool animation
            transform: state ? 'translateY(0)' : 'translateY(10px)',
            // cool expanding animation
            transition: 'transform 1s ease-in-out',
          }}
        >
          {state ? 'Copied to clipboard' : 'Email me'}
        </span>
      </a>
    </section>
  );
};

export default Contact;
