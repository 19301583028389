import React from 'react';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {TypographyStyleOptions} from '@mui/material/styles/createTypography';
import {ChildrenProp, ThemeInterface} from '../types';

function Mui({children}: ChildrenProp) {
  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins, sans-serif',
      body1: {
        color: 'white',
      },
      body2: {
        color: 'rgb(205,205,255)',
        fontSize: '2.5rem',
        fontWeight: 300,
      } as TypographyStyleOptions,
    },
    palette: {
      primary: {
        main: 'rgb(205,205,255)',
        dark: '#23283e',
      },
      text: {
        primary: '#90a0d9',
        dark: '#23283E',
        light: '#D7D7FF',
      },
    },
  } as unknown as ThemeInterface);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Mui;
